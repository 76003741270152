import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/ra.fakel.com.ua/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "компонент-admin"
    }}>{`Компонент `}<inlineCode parentName="h1">{`<Admin>`}</inlineCode></h1>
    <p>{`Компонент `}<inlineCode parentName="p">{`<Admin>`}</inlineCode>{` создает приложение с собственным хранилищем, маршрутизацией.
Принимает один обязательный параметр `}<inlineCode parentName="p">{`dataProvider`}</inlineCode>{` и хотя бы один потомок `}<inlineCode parentName="p">{`<Resource>`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dataProvider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DataProviderT`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`authProvider (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AuthProvider`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`i18nProvider (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`I18Provider`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`options (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AdminOptions`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h2>
    <pre><code parentName="pre" {...{}}>{`<Admin dataProvider={dataProvider}>
    <Resource
        name="posts"
        edit={PostsEdit}
        create={PostsCreate}
        list={PostView}
        show={PostShow}
        options={{ label: "Posts", icon: <BookOutlined /> }}
    />
    ...
</Admin>
`}</code></pre>
    <h2 {...{
      "id": "dataprovider"
    }}>{`DataProvider`}</h2>
    <p>{`Объект, который содержит набор методов необходимых для обращения к REST API.
Используются во `}<inlineCode parentName="p">{`view`}</inlineCode>{` компонентах, например `}<inlineCode parentName="p">{`getList()`}</inlineCode>{`, вызывается в `}<inlineCode parentName="p">{`<List>`}</inlineCode>{` компоненте для получения списка данных ресурса.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  getMany?: ( resource, params?) => Promise;
  getList: (resource, params?) => Promise;
  getOne: ( resource, params?) => Promise;
  create: ( resource, payload, params? ) => Promise;
  update: ( resource, payload, params? ) => Promise;
  delete: ( resource, params? ) => Promise;
};
`}</code></pre>
    <h2 {...{
      "id": "authprovider"
    }}>{`AuthProvider`}</h2>
    <p>{`Набор методов необходимых для обеспечения функциональности авторизации и аутентификаии.
Используется только, если вашему приложению нужна авторизация`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  login: (payload: LoginPayload) => Promise;
  logout: () => Promise;
  getMe: () => Promise;
  getPermissions: () => Promise;
  checkAuth: () => Promise<boolean>;
  checkError: (error) => Promise;
};
`}</code></pre>
    <h2 {...{
      "id": "i18provider"
    }}>{`i18Provider`}</h2>
    <p>{`Используется для обеспечения интернационализации.
Состоит лишь из одного метода `}<inlineCode parentName="p">{`translate`}</inlineCode>{`, необходимого для перевода сообщения`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    translate: (message: string) => string
};
`}</code></pre>
    <h2 {...{
      "id": "admin-options"
    }}>{`Admin Options`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`appName (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`appLogo (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`defaultView (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React.ComponentType`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`customLoginPage (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React.ComponentType`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      